<template>
    <section class="breadcrumbs" :style="image">
      <div class="container">
        <h2>{{recordStore.Title}}</h2>
        <h3>{{recordStore.SubTitle}}</h3>
      </div>
    </section>
</template>

<script>
const itineraryHeaderCover = importVueComp('components/itinerary', 'ItineraryHeaderCover', 'custom');
export default {
    name: 'custom-itinerary-header-cover',
    mixins: [itineraryHeaderCover,]
}
</script>


<style scoped>
h3 {
    font-size: 4vh !important;
    font-weight: 700;
    text-align: center;
    text-shadow: 0 2px 10px rgba(0, 0, 0, 0.65);
    position: relative;    
}

</style>
