
<script>
import '@/extra/custom/css/r-style.scss';
const reservationForm = importVueComp('components/reservationform', 'ReservationForm', 'custom');
export default {
    name: 'custom-reservation-form',
    mixins: [reservationForm],
    data () {
        return {
            accept: true,
            requiredFields: ['PaxName', 'Mobile', 'pax.FirstName', 'pax.LastName', 'pax.Email',
                'pax.BirthDate', 'pax.Passport', 'pax.PassportValidTo', 'pax.PassportCountry'],
        }
    },
}
</script>

