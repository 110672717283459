<template>
    <div class="container">
        <div class="col-12 info-box">
            <h4>{{tr('Terms & Conditions')}}</h4>
            <div class="info-box-body mt-3">
                <text-rows-style tag="p" :text="getTermsAndConditions"></text-rows-style>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'booking-final-terms',
    props: ['id', 'hash'],
    computed:{
        ...mapState({
            recordStore: state => state.main.record,
        }),
        langCode () {
           let r = _.find(api.tables.language, (c) => c.id == this.recordStore.LanguageId)
           if (r) return r.Code;
        },
        getTermsAndConditions () {
            if (!this.recordStore) return '';
            let r = _.find(api.tables.termsandconditions, (c) => c.LanguageId == this.recordStore.LanguageId);
            if (r) return r.Terms;
            return '';
        },
    },
    methods: {
        tr (t) {
            return tr(t, this.langCode);
        },
    }
}
</script>

