<template>
    <div class="row table-voucher">
        <div class="col-12 col-sm-6 text-center header-blue">{{'PREPAID TRAVEL VOUCHER'}}</div>
        <div class="col-12 col-sm-6 text-center"><span>{{'TRAVEL AGENT'}}:</span> {{ recordStore.SalesUser.FirstName }}</div>

        <div class="col-12 vc-border-bottom voucher-supplier">
            <p><span>{{'TO'}}:</span> {{ supplierData.Supplier.Name }}; {{ supplierData.Supplier.Address }} - Tel. {{ supplierData.Supplier.Phone }}</p>
            <p><span>{{'SERVICES INCLUDED'}}: </span><br>
                <div v-if="supplierData.Services">
                    <span v-for="(s, tId) in supplierData.Services" class="w-100">
                        <span> - </span>
                        <span class="voucher-service" v-if="!edit">
                            {{s.VoucherText || s.serviceName}} {{getDateServiceType(s)}}
                        </span>
                        <span class="voucher-service" v-else-if="edit && editing!= sId + '-' + tId" @click="editText(tId, s)">
                            {{s.VoucherText || s.serviceName}} {{getDateServiceType(s)}}
                        </span>
                        <input type="text" class="input-voucher w-75" v-else v-model="s.VoucherText">
                        <span v-if="s.PickupInfo" class="w-25 font-italic bold">
                            <span @click="editPickup(sId, s)">PICK UP:</span>
                            <span v-if="!edit" @click="editPickup(sId, s)" class="bold">{{ s.PickupInfo }}</span>
                            <span v-else-if="edit && editing!= sId + '-pickup'" @click="editPickup(sId, s)" class="bold">
                                {{ s.PickupInfo }}
                            </span>
                            <input type="text" class="input-voucher" v-else-if="edit && editing == sId + '-pickup'" v-model="s.PickupInfo">
                        </span>
                        <br>
                    </span>
                </div>
                <div v-else-if="supplierData.RoomsText">
                    <span class="voucher-service" v-for="(t, hId) of supplierData.RoomsText">
                        <span class="voucher-service" v-if="!edit">
                            {{t}}
                        </span>
                        <span class="voucher-service" v-else-if="edit && editingRoom!= hId" @click="editRoomText(hId, t)">
                            {{t}}
                        </span>
                        <input type="text" class="input-voucher w-100" v-else v-model="roomText" @change="setRoomText">
                        <br>
                    </span>
                </div>
                <div v-else-if="supplierData.VoucherText">
                    <span class="voucher-service">
                        {{supplierData.VoucherText }}
                    </span>
                </div>
            </p>
        </div>

        <div class="col-sm-6 vc-border-bottom">
            <p class="vcenter"><span>{{'CLIENT'}}:</span> {{ recordStore.PaxName }}</p>
            <p v-if="recordStore.BaseQuotes[Object.keys(recordStore.BaseQuotes)[0]]">
                <span>{{'N° OF ADULTS'}}:</span>
                {{ recordStore.BaseQuotes[Object.keys(recordStore.BaseQuotes)[0]].ADT }}
                <br>
                <span>{{'N° OF CHILDREN'}}:</span>
                {{ recordStore.BaseQuotes[Object.keys(recordStore.BaseQuotes)[0]].CHD }}
            </p>
        </div>
        <div class="col-sm-6 vc-border-bottom vc-border-left">
            <p>
            <span>{{'EMERGENCY CONTACT'}}:</span>
            <text-rows-style class="p-mo-margin"
            :text="recordStore.EmergencyContacts"
            ></text-rows-style>
            </p>
        </div>
        <div class="col-6 no-border">
            <div class="row no-border">
                <div class="col-12 vc-border-bottom">
                    <p><span>{{'DATE IN'}}:</span> {{ supplierData.DateIn }}</p>
                </div>
                <div class="col-12 vc-border-bottom">
                    <p><span>{{'DATE OUT'}}:</span> {{ supplierData.DateOut }}</p>
                </div>
                <div class="col-12 no-border">
                    <p>
                        <span @click="setComment('Comment', supplierData, supplierData.key)">{{'COMMENTS'}}:</span>
                        <span v-if="!edit" class="voucher-service">{{ supplierData.Comments }}</span>
                        <span class="voucher-service" v-else-if="edit && editing!= sId + '-Comment'" @click="setComment('Comment', supplierData, supplierData.key)">
                            {{ supplierData.Comments }}
                        </span>
                        <input type="text" class="input-voucher w-75" v-else v-model="recordStore.VoucherComments[supplierData.key]">
                    </p>
                </div>
            </div>
        </div>
        <div class="col-6 vc-border-left">
            <async-img :path="imgPath" img="logo-voucher.png" class="voucher-logo"></async-img>
        </div>
    </div>
</template>

<script>
import '@/extra/custom/css/voucher.scss';
const voucher = importVueComp('components/voucher', 'Voucher', 'custom');
import { mapState } from 'vuex';
export default {
    name: 'voucher',
    mixins: [voucher],
}
</script>
