
<script>
import '@/extra/custom/css/fstyles.scss';
let bookingFinalView = importVueComp('components/documentation', 'BookingFinalView', 'custom');
export default {
    name: 'custom-booking-final-view',
    mixins: [bookingFinalView],
}
</script>

