<script>
const bookingQuoteHotelRoom = importVueComp('components/booking', 'BookingQuoteHotelRoom', 'custom');
export default {
    name: 'custom-booking-quote-hotel-room',
    mixins: [bookingQuoteHotelRoom],
    computed: {
        hotelValues () {
            if (this.room.Package) return {}
            return this.$store.state.booking.hotelValues;
        },
    }
}
</script>

