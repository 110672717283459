const Docx = vueTools.importModule('docx', 'tools', 'custom').default;
var EventBus = require('@/tools/event-bus').default;

class CustomDocx extends Docx {

    /*get brandColor () {
        return '4D2765';
    }

    get textColor () {
        return '000000';
    }*/



    getPriceTableCell () {
        if (this.booking.HideTotalPrice) return
        return super.getPriceTableCell();
    }

    getServiceTitleName (s) {
        let r = this.getServiceTitle(s);
        if (!r && s.Name) r = s.Name;
        if (!r && s.Service && s.Service.Name) r = s.Service.Name;
        let optionalPrice = this.personPrice(s);
        if (optionalPrice) {
            r = `${r}. ${this.tr('Price per Person')}: ${optionalPrice}`;
        }
        return r;
    }

    personPrice (s) {
        if (s && !s.Optional) return;
        if (s && s.Quote && s.Quote.QuoteList) {
            for (let baseId in this.booking.BaseQuotes){
                if (!s.Quote.QuoteList[baseId]) continue;
                if (!s.Quote.QuoteList[baseId].ADT) continue;
                if (!this.booking.Totals) continue
                if (!this.booking.Totals[baseId]) continue
                if (!this.booking.Totals[baseId].ADT) continue
                if (!this.booking.Totals[baseId].ADT.Selected) continue
                let price = s.Quote.QuoteList[baseId].ADT.Price;
                if (price) {
                    return `${this.booking.CurrencyId} ${Math.round(price, 0)}`
                }

            }
        }
    }



}

export default CustomDocx;
