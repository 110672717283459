<template>
  <section class="data">
    <div class="container">
      <div class="row justify-content-evenly">
        <div class="col-lg-12 mt-4 mt-lg-0">
          <div class="data-description">
            <h2 class="text-center">{{recordStore.Title}}</h2>
            <h5 class="text-center">{{recordStore.SubTitle}}</h5>
            <text-rows-style
                class="italic text-justify"
                tag="p"
                :text="recordStore.Summary"
                v-if="!edit"
                @edit="setEdit"
            ></text-rows-style>
            <text-area-edit-style
                tag="p"
                v-else
                :disabled="!canEdit"
                :text.sync="recordStore.Summary"
              ></text-area-edit-style>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
const itineraryHeaderSummary = importVueComp('components/itinerary', 'ItineraryHeaderSummary', 'custom');
export default {
    name: 'custom-itinerary-header-summary',
    mixins: [itineraryHeaderSummary,]
}
</script>


<style scoped>

</style>
