import { render, staticRenderFns } from "./ItineraryHeaderSummary.vue?vue&type=template&id=349cef6e&scoped=true&"
import script from "./ItineraryHeaderSummary.vue?vue&type=script&lang=js&"
export * from "./ItineraryHeaderSummary.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "349cef6e",
  null
  
)

export default component.exports