
<script>
const vouchersPreview = importVueComp('components/voucher', 'VouchersPreview', 'custom');
//import '@/extra/custom/css/voucher.scss';
export default {
    name: 'custom-vouchers-preview',
    mixins: [vouchersPreview],
    methods: {
        getVoucherKey (s) {
            let cityId = s.CityId;
            if (!cityId && s.Service && s.Service.CityId) cityId = s.Service.CityId;
            if (!cityId) cityId = '-';
            return s.Supplier.id + '.' + cityId;
        },
        getPageBreak (i) {
            if (i==Object.keys(this.getSuppliersAndHotels).length) return false;
            if (i % 2 == 0) return true;
            return false;
        },
    }
}
</script>
