<template>
    <div id="b-invoice" v-if="ready" @contextmenu="handleClick($event)">
        <div class="button-fixed">
            <button type="button" class="btn btn-primary action-button"
                @click="print()">
                <font-awesome-icon icon="print"/>
            </button>
        </div>
        <div class="container container-body border">
            <div class="row mb-5">
                <!--div class="col-12 row">
                    <div class="col-12">
                        <h2>
                            {{getDocName}}
                        </h2>
                    </div>
                </div-->
                <div class="col-6">
                    <div class="col-12">
                        <h2>
                            BALANCE NR. {{recordStore.id}}
                        </h2>
                    </div>
                    <div class="col-12">
                        Quarum Travel S.A.
                    </div>
                    <div class="col-12">
                        REG. No. 11.667
                    </div>
                    <div class="col-12">
                        CUIT: 30-70872392-0
                    </div>
                    <div class="col-12">
                        Av. Coronel Díaz 1450 1º A - Bs. As. - Argentina
                    </div>
                    <!--div class="col-12">
                        <a href="mailto:info@nubbo.io">
                            info@nubbo.io
                        </a>
                    </div-->
                    <div class="col-12">
                        Phone: +5411 4827 5700
                    </div>
                </div>
                <div class="col-6 d-flex align-items-center">
                    <div class="col-12 text-right">
                        <img width="200" id="logo" src="@/extra/custom/img/logo-full.png" class="">
                    </div>
                </div>
                <div class="col-8 border-top mt-2">
                    <div class="col-12">
                        Id. de cliente: {{booking.PaxName}}
                    </div>
                    <div class="col-12 pl-0" v-if="booking.Agency">
                        <div class="col-12">
                            <strong>Company:</strong> {{booking.Agency.Name}}
                        </div>
                        <div class="col-12" v-for="row of agencyAddress">
                            {{row}}
                        </div>
                    </div>
                </div>
                <div class="col-4 border-top mt-2  d-flex align-items-center">
                    <div class="col-12">
                        Date: {{getDate(new Date())}}
                    </div>
                </div>
            </div>
            <div class="row mt-5 border-top div-table">
                <table class="w-100">
                    <thead>
                        <tr v-if="!recordStore.HideInvoiceServices">  
                            <td class="p-2 pl-4">Date</td>
                            <td class="p-2 td-border">Service Description</td>
                            <td class="p-2 td-border text-right">Unit Price</td>
                            <td class="p-2 td-border text-right">Qty #</td>
                            <td class="p-2 pr-4 text-right">Total</td>
                        </tr>
                        <tr v-else>  
                            <td class="p-2 td-border" colspan="4">Service Description</td>
                            <td class="p-2 pr-4 text-right">Total</td>
                        </tr>

                    </thead>

                    <tbody v-if="!recordStore.HideInvoiceServices">
                        <tr class="" v-for="s of services">
                            <td class="p-2 pl-4">{{ s.ServiceDate  | toLocalDate }}</td>
                            <td class="p-2 td-border">{{ s.serviceName }}</td>
                            <td class="p-2 td-border text-right">{{ (s.serviceTotal.Price / s.paxQuantity).toFixed(2) }}</td>
                            <td class="p-2 td-border text-right">{{ s.paxQuantity }}</td>
                            <td class="p-2 td-border pr-4 text-right">{{ s.serviceTotal.Price.toFixed(2) }}</td>
                        </tr>
                    </tbody>
                    
                    <tbody v-else>
                        <tr class="td-border-bottom" >                        
                            <td class="pl-2 p-2" colspan="4"> <strong>Total services: {{ booking.Title }} </strong></td>
                            <td class="p-2 td-border pr-4 text-right">{{ amount }}</td>                        
                        </tr>
                    </tbody>
                    <tfoot class="border-top">
                        <tr> 
                            <td class="p-2"></td>
                            <td class="p-2" style="width: 50%;"></td>
                            <td class="p-2 td-border td-border-bottom text-center" colspan="2">TOTAL {{ this.booking.PriceCurrencyId }}</td>
                            <td class="p-2 td-border td-border-bottom pr-4 text-right">{{amount}}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style="width: 50%;"></td>
                            <td class="p-2 td-border td-border-bottom text-center" colspan="2">{{tr('Deposit')}}: </td>
                            <td class="p-2 text-right td-border-bottom td-border pr-4">
                                <span v-if="booking.InvoiceDeposit">
                                    {{ (booking.InvoiceDeposit).toFixed(2) }}
                                </span>
                                <span v-else>
                                    -
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style="width: 50%;"></td>
                            <td class="p-2 td-border td-border-bottom text-center" colspan="2">{{tr('Balance')}}: </td>
                            <td class="p-2 td-border td-border-bottom text-right pr-4">
                                <span v-if="booking.InvoiceBalance">
                                    {{ (booking.InvoiceBalance).toFixed(2) }}
                                </span>
                                <span v-else>
                                    -
                                </span>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div v-if="!ifText(booking.InvoiceBankDetails)" class="row mt-2 border-top">
                <div class="col-12">
                    <div class="col-12">
                        Bank Details:
                    </div>
                    <div class="col-12">
                        Banca Sella - ITALY
                    </div>
                    <div class="col-12">
                        Quarum Travel S.A.
                    </div>
                    <div class="col-12">
                        Swift code SELBIT2B
                    </div>
                    <div class="col-12">
                        IT38E0326822300078896218782
                    </div>
                    <div class="col-12">
                        USD Account N° 078896218782
                    </div>
                    <div class="col-12 text-danger">
                        NOTA BENE: Per favore considerare al momento di effetuare il bonifico di scegliere l´opzione ¨libero di spesse¨
                    </div>
                </div>
            </div>
            <div v-if="ifText(booking.InvoiceBankDetails)" class="row mt-2 border-top">
                <div class="col-12">
                    <div v-html="invoiceBankDetails" class="col-12">                                    
                    </div>
                </div>
            </div>
            <div class="row mt-2 border-top">
                <div class="col-12">
                    <div class="col-12">
                        Cancellation Policy
                    </div>
                    <div class="col-12">
                        Up to 25 days before the arrival date: no charge will apply
                    </div>
                    <div class="col-12">
                        25-15 days: 50% of the total amount shall be charged as cancellation fee.
                    </div>
                    <div class="col-12">
                        14-08 days: 70% of the total amount shall be charged as cancellation fee.
                    </div>
                    <div class="col-12">
                        7-0 days: 100% of the total amount shall be charged
                    </div>
                </div>
            </div>
            <div class="row mt-2 border-top">
                <div class="col-12">
                    <div class="col-12">
                        Important:
                    </div>
                    <div class="col-12">
                        DON’T ADD ANY NAME OR ADRESS TO THE ABOVE in order to avoid penalties or surcharges.
                    </div>
                    <div class="col-12">
                        We kindly ask you to send us the copy with swift information to be able to seek for each 
                        transfer quicker, in order to do a correct follow up ofpayments credited in our account
                    </div>
                    <div class="col-12">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
let bookingInvoice = importVueComp('components/documentation', 'BookingInvoice', 'custom');
export default {
    name: 'custom-booking-invoice',
    mixins: [bookingInvoice],
    computed: {
        services () {
            let res = [];
            if (!this.recordStore) return res;
            for (let day of this.recordStore.BookingDays) {
                for (let s of day.BookingDayServices) {
                    if (s.Package) continue;
                    if (!s.serviceName) continue;
                    res.push(s)
                }
            }
            return res;
        },
        amount () {
            if (!this.payment) {
                return this.getTotalInCurrency(this.recordStore.finalTotalWithFlights).toFixed(2);
            }
            let row = _.find(this.receipts, (r) => r.id == this.payment);
            if (row) {
                return row.CurrencyId + ' ' + row.Amount.toFixed(2);
            }
        },
        invoiceBankDetails () {
            if (this.recordStore.InvoiceBankDetails) {
                return this.recordStore.InvoiceBankDetails
            }
        }
    },
    methods: {
        ifText (s) {
            return tools.ifText(s);
        },
    }
}
</script>

<style scoped>
.border-top {
    border-top: solid 2px !important;
}
.border {
    border: solid 2px !important;
}
thead {
    background-color: #06126C;
    color: white
}

table .td-border {
  border-left: 2px solid #000;
}

table .td-border-bottom {
  border-bottom: 2px solid #000;
}

.div-table {
    min-height: 40vh;
}

table tr, thead, td {
    max-height: 30px !important;
}
table {
    height: fit-content;
}

</style>