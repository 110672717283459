<template>
  <div id="quarum-footer">
    <footer id="footer">
        <div class="footer-content">
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <div class="icon-box effect small clean">
                            <div class="icon text-center">
                                <font-awesome-icon :icon="['far', 'clock']" style="height: 75%; width: 75%"/>
                            </div>
                            <h3>{{tr('Working Hours')}}</h3>
                            <p><strong>{{tr('Monday')}} - {{tr('Friday')}}</strong>
                                <br>09:00 - 18:00 hs</p>
                            <p><strong>{{tr('Time Zone')}}</strong>
                                <br>UTC/GMT -3 {{tr('hours')}}</p>
                            <p><strong>{{tr('Emergencies')}}</strong>
                                <br>24HS</p>

                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="icon-box effect small clean">
                            <div class="icon text-center">
                                <a href="https://goo.gl/maps/x9w7F6Aj7R2Z75G47">
                                <font-awesome-icon icon="map-marker-alt" style="height: 75%; width: 75%"/>
                                </a>
                            </div>
                            <h3>{{tr('Argentina Office')}}</h3>
                            <p><strong>{{tr('Address')}}:</strong>
                                <br> <a href="https://goo.gl/maps/x9w7F6Aj7R2Z75G47">Av. Coronel Diaz 1450, Piso 1
                                <br> Buenos Aires, C1054AAV </a>
                                <br>
                                <p><strong>{{tr('Phone')}}:</strong>
                                <br><a href="tel:+541148275700">+5411 4827 5700</a>
                                </p>
                                <br>
                            </p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="icon-box effect small clean">
                            <div class="icon text-center">
                                <a href="https://goo.gl/maps/x9w7F6Aj7R2Z75G47">
                                <font-awesome-icon icon="map-marker-alt" style="height: 75%; width: 75%"/>
                                </a>
                            </div>
                            <h3>{{tr('Chile Office')}}</h3>
                            <p><strong>{{tr('Address')}}:</strong>
                                <br> <a href="https://goo.gl/maps/g3a3bu2PRYvvkoPDA">Padre Mariano 391, Oficina 704
                                <br> Providencia, Sgo. de Chile, C7500000 </a>
                                <br>
                                <p><strong>{{tr('Phone')}}:</strong>
                                <br><a href="tel:+56226314100">+5622 2631 4100</a>
                                </p>
                                <br>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright-content">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="social-icons social-icons-colored float-left">
                            <ul>
                                <li class="social-instagram"><a href="https://www.instagram.com/quarumtravelargentina/">
                                    <font-awesome-icon :icon="['fab', 'instagram']" style="height: 50%; width: 50%"/>
                                </a></li>
                                <li class="social-facebook"><a href="https://www.facebook.com/Quarum-Travel-2240883765935703">
                                    <font-awesome-icon :icon="['fab', 'facebook-f']" style="height: 50%; width: 50%"/>
                                </a></li>
                                <li class="social-linkedin"><a href="https://www.linkedin.com/in/quarumtravel">
                                    <font-awesome-icon :icon="['fab', 'linkedin']" style="height: 50%; width: 50%"/>
                                </a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="copyright-text text-center">&copy; 2023 QUARUM - {{tr('All rights reserved')}}. <a href="mailto:rrhh@quarum.com.ar">{{tr('Work with us')}}.</a></div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  </div>
</template>

<script>
import '@/extra/custom/css/footer.scss';
import { mapState } from 'vuex';
export default {
    name: 'custom-itinerary-footer',
    props: ['langCode'],
    methods: {
        tr (t) {
            return tr(t, this.langCode);
        }
    }
}
</script>


<style scoped>
</style>