<script>
const bookingDetail = importVueComp('components/booking', 'BookingDetail', 'custom');
export default {
    name: 'custom-booking-detail',
    mixins: [bookingDetail],
    computed: {
        canAddPackage () {
            if (this.recordStore && this.recordStore.BaseQuotes && Object.keys(this.recordStore.BaseQuotes.bases).length == 0) return false;
            return true; 
        },
    },
    methods: {
        setActionButtons (self) {
            bookingDetail.methods.setActionButtons(this);
            this.actionButtons.splice(3, 0, {
                text: 'Paquetes',
                action: () => {
                    this.templateTypes = ['NONE'];
                    this.showTemplateSelector(false)
                },
                class: 'btn btn-outline-success btn-action-day ml-2 mt-1',
                colClass: {'col-5': !this.compressView, 'col-3': this.compressView},
                icon: 'plus',
                disabledIf: () => !this.templates || !this.canAddPackage,
                id: 'add-template'
            })
        }
    },
}
</script>



