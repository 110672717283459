<script>
var EventBus = require('@/tools/event-bus').default;
const bookingWindow = importVueComp('components/booking', 'BookingWindow', 'custom');
export default {
    name: 'custom-booking-window',
    mixins: [bookingWindow],
    computed: {
        getName () {
            if (this.recordStore && this.recordStore.bookingConfirmed) return 'File';
            if (this.recordStore && this.recordStore.Status == 'PACKAGE') return 'Paquete';
            return tr('Quotation');
        }

    },
    methods: {
        getRouteName (currentRoute) {
            let routeName = bookingWindow.methods.getRouteName(currentRoute);
            if (currentRoute.name == 'booking-package') routeName = 'abm-booking-packages';
            return routeName;
        },
    }
}
</script>
