<template>
    <div v-if="langCode && recordStore && (hotelsByDay[day.DayNr] || day.PaxHotel==1)" >
        <div class="entry-meta icon-box not-break-inside">
            <i class="ri-hotel-line"></i>
            <div v-if="hotelsByDay[day.DayNr]">
              <h4>{{tr('Accommodation')}}</h4>
                {{getHotelName()}}
              <br><a :href="'https://' + getHotelWeb()" target="_blank">{{getHotelWeb()}}</a>
            </div>
            <div v-else-if="day.PaxHotel==1">
              <h4>{{tr('Accommodation')}}</h4>
              <p>
                {{tr('Booked by yourself')}}
              </p>
            </div>
        </div>
    </div>
</template>

<script>
const itineraryBodyHotel = importVueComp('components/itinerary', 'ItineraryBodyHotel', 'custom');
export default {
    name: 'custom-itinerary-body-hotel',
    mixins: [itineraryBodyHotel],
    methods: {
        getHotelWeb () {
            let res = [];
            if (this.hotelsByDay[this.day.DayNr] && this.hotelsByDay[this.day.DayNr].BookingDayRooms) {
                for (let room of this.hotelsByDay[this.day.DayNr].BookingDayRooms) {
                    if (!room.Hotel) continue;
                    if (!room.Hotel.WebSite) continue;
                    return room.Hotel.WebSite.replace('https://', '');
                }
            }
            return '';
        }
    }
}
</script>


