<template>
    <div class="col-md-3 mt-2 d-flex flex-column pl-3">
        <booking-quote-config-checkbox></booking-quote-config-checkbox>
        <div class="form-check checkbox-group">
            <input v-model="record.HideInvoiceServices" 
                class="form-check-input" 
                type="checkbox"
                @change="change('HideInvoiceServices')"
                id="HideInvoiceServices">
            <label for="HideInvoiceServices" class="smaller form-check-label">{{tr('HideInvoiceServices')}}</label>
        </div>
    </div>
</template>

<script>
const bookingQuoteConfigCheckbox = importVueComp('components/booking', 'BookingQuoteConfigCheckbox', 'custom');
export default {
    name: 'custom-booking-quote-config-checkbox',
    mixins: [bookingQuoteConfigCheckbox],
    components: {
        'booking-quote-config-checkbox': bookingQuoteConfigCheckbox,
    },
}
</script>

