<template>
    <div v-if="ready">
        <div class="card no-border-top">
            <div class="card-header">
                <div class="form-row">
                    <div class="col-md-6 col-sm-12 mb-1 px-3">
                        <div class="form-row">
                            <div class="form-group col-md-12 mb-0">
                                <div class="card-header section-title col-12 py-1">
                                    <label>{{tr('Includes')}}</label>
                                </div>
                             </div>
                        </div>
                        <text-area-style
                            :disabled="!canEdit"
                            :text.sync="recordStore.Include"
                            :pasteText="recordStore._Include"
                            maxHeight="200"
                        ></text-area-style>
                    </div>
                    <div class="col-md-6 col-sm-12 mb-1 px-3">
                        <div class="form-row">
                            <div class="form-group col-md-12 mb-0">
                                <div class="card-header section-title col-12 py-1">
                                    <label>{{tr('Not Includes')}}</label>
                                </div>
                             </div>
                        </div>
                        <text-area-style
                            :disabled="!canEdit"
                            :text.sync="recordStore.NotInclude"
                            :pasteText="recordStore._NotInclude"
                            maxHeight="200"
                        ></text-area-style>
                    </div>
                    <div class="col-md-6 col-sm-12 mb-1 px-3 mt-3">
                        <div class="form-row">
                            <div class="form-group col-md-12 mb-0">
                                <div class="card-header section-title col-12 py-1">
                                    <label>{{tr('Notes')}}</label>
                                </div>
                             </div>
                        </div>
                        <text-area-style
                            :disabled="!canEdit"
                            :text.sync="recordStore.Comment"
                            :pasteText="recordStore._Comment"
                            maxHeight="200"
                        ></text-area-style>
                    </div>
                    <div class="col-md-6 col-sm-12 mb-1 px-3 mt-3">
                        <div class="form-row">
                            <div class="form-group col-md-12 mb-0">
                                <div class="card-header section-title col-12 py-1">
                                    <label>{{tr('Emergency Contacts')}}</label>
                                </div>
                             </div>
                        </div>
                        <text-area-style
                            :text.sync="recordStore.EmergencyContacts"
                            :pasteText="recordStore._EmergencyContacts"
                            maxHeight="200"
                        ></text-area-style>
                    </div>
                    <div class="col-md-6 col-sm-12 mb-1 px-3 mt-3">
                        <div class="form-row">
                            <div class="form-group col-md-12 mb-0">
                                <div class="card-header section-title col-12 py-1">
                                    <label>{{tr('Bank Details')}}</label>
                                </div>
                             </div>
                        </div>
                        <text-area-style
                            :text.sync="recordStore.InvoiceBankDetails"
                            :pasteText="recordStore._InvoiceBankDetails"
                            maxHeight="200"
                        ></text-area-style>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const bookingInfo = importVueComp('components/booking', 'BookingInfo', 'custom');
export default {
    name: "custom-booking-info",
    mixins: [bookingInfo],
};
</script>