<template>
    <div v-if="showService">
        <b v-if="s.Optional">
            {{titleOptional}}.
            <i v-if="personPrice">{{tr('Price per Person')}}: {{personPrice}}. </i>
            <i class="text-danger">{{tr('Optional Service')}} </i>
        </b>
        <b v-else-if="title">
            {{title}}
        </b>
        <text-rows-style
            class="italic text-justify mb-0"
            :text="text"
            v-if="s && ifDescription && !edit"
            @edit="setEdit"
        ></text-rows-style>
        <text-area-edit-style
            v-else-if="s && edit"
            :text.sync="s.Description"
         ></text-area-edit-style>
        <p v-if="s.Service.ServiceType && !s.Service.NotPrice && ifText && (readMore || text.length < limit)"  class="mb-0">
            <i><b>{{tr('Type of service')}}:</b> {{tr(s.Service.ServiceType.Name)}}</i>
        </p>
        <p v-if="s.EntranceIncluded">
            <strong><i>{{tr('_entrance_includes')}}</i></strong>
        </p>
        <p v-if="recordStore.ShowServiceLanguage && s.Service.languageOffered && show">
            <strong>
                <i>{{tr('Languages')}}: {{getLanguageOffered(s.Service)}}</i>
            </strong>
        </p>
        <br>
    </div>
</template>

<script>
const itineraryBodyService = importVueComp('components/itinerary', 'ItineraryBodyService', 'custom');

export default {
    name: 'custom-itinerary-body-service',
    mixins: [itineraryBodyService],
    computed: {
        personPrice () {
            if (this.s && !this.s.Optional) return;
            if (this.s && this.s.Quote && this.s.Quote.QuoteList) {
                for (let baseId in this.recordStore.BaseQuotes.bases){
                    if (!this.s.Quote.QuoteList[baseId]) continue;
                    if (!this.s.Quote.QuoteList[baseId].ADT) continue;
                    if (!this.recordStore.Totals) continue
                    if (!this.recordStore.Totals[baseId]) continue
                    if (!this.recordStore.Totals[baseId].ADT) continue
                    if (!this.recordStore.Totals[baseId].ADT.Selected) continue
                    let price = this.s.Quote.QuoteList[baseId].ADT.Price;
                    if (price) {
                        return `${this.recordStore.CurrencyId} ${Math.round(price, 0)}`
                    }

                }
            }
        }
    }
}
</script>


