<script>
const bookingQuoteService = importVueComp('components/booking', 'BookingQuoteService', 'custom');
export default {
    name: 'custom-booking-quote-service',
    mixins: [bookingQuoteService],
    computed: {
        costValues () {
            if (this.service.Package) return {}
            return this.$store.state.booking.costValues;
        },
    },
}
</script>

