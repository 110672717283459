const pageBooking = importVueComp('components/pages', 'PageBooking');
const bookingWindow = importVueComp('components/booking', 'BookingWindow');

function getCustomRoutes () {
    let res = [];
    res.push({ path: '/abm/bookingpackage', name: 'abm-booking-packages', component: pageBooking,
        props: {template: 'NONE', sales: true, title: 'Paquetes', status: 'PACKAGE', 'table': 'package'}})
    res.push({ path: '/abm/bookingpackage/:id?', name: 'booking-package', component: bookingWindow, props: true})
    let remove = []
    return {routes: res, remove: remove};
}

export default getCustomRoutes();